import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-3037e247"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "global-table"
};
const _hoisted_2 = {
  style: {
    "display": "flex",
    "align-items": "center",
    "justify-content": "center"
  }
};
const _hoisted_3 = {
  key: 0
};
import { ref, reactive, computed, onMounted } from 'vue';
import { Refresh, Setting, DocumentAdd, DocumentDelete } from '@element-plus/icons-vue'; // DocumentDelete

import { getstoreList, getstocksMenuLiat } from '@/api/general';
import { getGoodsstocksList } from '@/api/goods';
import modalInventory from './modal/modal-inventory.vue';
import modalCar from './modal/model-car.vue';
export default {
  __name: 'inventory',

  setup(__props, {
    expose: __expose
  }) {
    const modalInventoryRef = ref();
    const showcarButton = ref(true); // 是否显示出库/入库按钮

    const showModal = data => {
      console.log('库存列表接收', data);
    };

    const selectedColumns = ref(['ID', '商品图', '商品条码', '商品名称', '规格型号', '库存单位', '库存量', '进货价']);
    const tableData = ref([]);
    const loading = ref(false);

    const generateFrom = () => {
      return {
        start_datetime: null,
        end_datetime: null,
        storeId: null,
        carId: null,
        subject: null
      };
    };

    const formModel = ref(generateFrom());
    const pagination = reactive({
      page_num: 1,
      page_size: 10,
      total: 0
    });
    const sendParams = computed(() => {
      return { ...pagination,
        ...formModel.value
      };
    }); // 日期选择
    // const dateArr = ref()
    // const changeDate = (dateArr) => {
    //   if (dateArr && dateArr.length > 0) {
    //     formModel.value.start_datetime = dateArr[0]
    //     formModel.value.end_datetime = dateArr[1]
    //   } else {
    //     formModel.value.start_datetime = ''
    //     formModel.value.end_datetime = ''
    //   }
    // }
    // 获取表格数据

    const getTableData = async () => {
      if (sendParams.value.storeId === null) {
        tableData.value = [];
        return;
      }

      loading.value = true;

      try {
        const resData = await getGoodsstocksList(sendParams.value);

        if (resData.status === 'success') {
          tableData.value = resData.data;
          Object.assign(pagination, resData.paging);

          if (sendParams.value.carId) {
            showcarButton.value = false;
          } else {
            showcarButton.value = true;
          }
        }

        loading.value = false;
      } catch (err) {
        console.log(err);
        loading.value = false;
      }
    };

    const StorelistData = ref([]); // 获取服务站列表数据

    const getStoreData = async () => {
      try {
        const resData = await getstoreList();

        if (resData.status === 'success') {
          StorelistData.value = resData.data;
        } else {
          console.log('获取服务站数据失败了。。。');
        }
      } catch (err) {
        console.log(err);
      }
    };

    const serveChange = e => {
      if (e && e.length > 0) {
        formModel.value.carId = null;
        formModel.value.storeId = e[1];
        getRackData({
          storeId: formModel.value.storeId
        });
      } else {
        formModel.value.storeId = null;
        formModel.value.carId = null;
      }
    };

    const racklistData = ref([]); // 获取货架列表数据

    const getRackData = async data => {
      try {
        const resData = await getstocksMenuLiat(data);

        if (resData.status === 'success') {
          racklistData.value = resData.data;
        } else {
          console.log('获取货架数据失败了。。。');
        }
      } catch (err) {
        console.log(err);
      }
    };

    const rackChange = e => {
      if (e && e.length > 0) {
        formModel.value.carId = e[0];
      } else {
        formModel.value.carId = null;
      }
    }; // 打开入库、出库弹窗


    const openModalInventory = (type, record) => {
      // console.log('打开入库、出库弹窗', formModel.value.carId)
      modalInventoryRef.value.showModal({
        type: type,
        StorelistData: StorelistData.value,
        id: record === null || record === void 0 ? void 0 : record.id,
        record: record,
        storeId: formModel.value.storeId,
        carId: formModel.value.carId
      });
    };

    const modalCarRef = ref();

    const addcar = () => {
      modalCarRef.value.showModal({
        storeId: formModel.value.storeId,
        StorelistData: StorelistData.value
      });
    }; // 页码变动


    const handleCurrentChange = async page => {
      pagination.page_num = page;
      await getTableData();
    };

    const onSubmit = () => {
      console.log('submit!');
      getTableData();
    }; // 重置


    const restSubmit = e => {
      if (userInfo.value.storeId) {
        formModel.value = generateFrom();
        formModel.value.storeId = userInfo.value.storeId;
      } else {
        formModel.value = generateFrom();
      }

      Object.assign(pagination, {
        page_num: 1,
        page_size: 10,
        total: 0
      });
      getTableData();
    }; // 设置默认服务站id


    const userInfo = ref(JSON.parse(localStorage.getItem('userInfo')));

    const setServeId = async () => {
      if (userInfo.value.storeId) {
        formModel.value.storeId = userInfo.value.storeId;
        getRackData({
          storeId: formModel.value.storeId
        });
        getTableData();
      }
    }; // 初始化


    const init = async () => {
      // await getTableData() // 获取表格数据
      getStoreData(); // 获取服务站列表数据

      setServeId();
    };

    onMounted(() => {
      init();
    });

    __expose({
      showModal
    });

    return (_ctx, _cache) => {
      const _component_el_cascader = _resolveComponent("el-cascader");

      const _component_el_form_item = _resolveComponent("el-form-item");

      const _component_el_input = _resolveComponent("el-input");

      const _component_Plus = _resolveComponent("Plus");

      const _component_el_icon = _resolveComponent("el-icon");

      const _component_el_button = _resolveComponent("el-button");

      const _component_el_form = _resolveComponent("el-form");

      const _component_el_col = _resolveComponent("el-col");

      const _component_el_row = _resolveComponent("el-row");

      const _component_el_tooltip = _resolveComponent("el-tooltip");

      const _component_el_divider = _resolveComponent("el-divider");

      const _component_el_checkbox = _resolveComponent("el-checkbox");

      const _component_el_checkbox_group = _resolveComponent("el-checkbox-group");

      const _component_a_table_column = _resolveComponent("a-table-column");

      const _component_el_image = _resolveComponent("el-image");

      const _component_a_table = _resolveComponent("a-table");

      const _component_el_pagination = _resolveComponent("el-pagination");

      const _directive_loading = _resolveDirective("loading");

      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", null, [_createVNode(_component_el_row, {
        class: "global-search"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_col, {
          span: 24
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form, {
            inline: true,
            model: formModel.value
          }, {
            default: _withCtx(() => [_createVNode(_component_el_form_item, {
              label: "服务站"
            }, {
              default: _withCtx(() => [_createVNode(_component_el_cascader, {
                modelValue: formModel.value.storeId,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => formModel.value.storeId = $event),
                options: StorelistData.value,
                onChange: serveChange,
                disabled: userInfo.value.storeId ? true : false,
                clearable: "",
                style: {
                  "width": "260px"
                }
              }, null, 8, ["modelValue", "options", "disabled"])]),
              _: 1
            }), _createVNode(_component_el_form_item, {
              label: "移动货架"
            }, {
              default: _withCtx(() => [_createVNode(_component_el_cascader, {
                modelValue: formModel.value.carId,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => formModel.value.carId = $event),
                options: racklistData.value,
                onChange: rackChange,
                clearable: "",
                disabled: formModel.value.storeId === null,
                style: {
                  "width": "260px"
                }
              }, null, 8, ["modelValue", "options", "disabled"])]),
              _: 1
            }), _createVNode(_component_el_form_item, {
              label: "商品名称"
            }, {
              default: _withCtx(() => [_createVNode(_component_el_input, {
                modelValue: formModel.value.subject,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => formModel.value.subject = $event),
                placeholder: "请输入",
                style: {
                  "width": "260px"
                },
                clearable: ""
              }, null, 8, ["modelValue"])]),
              _: 1
            }), _createVNode(_component_el_form_item, null, {
              default: _withCtx(() => [_createVNode(_component_el_button, {
                onClick: addcar,
                disabled: formModel.value.storeId === null
              }, {
                default: _withCtx(() => [_createVNode(_component_el_icon, null, {
                  default: _withCtx(() => [_createVNode(_component_Plus)]),
                  _: 1
                }), _createTextVNode("新增移动货架")]),
                _: 1
              }, 8, ["disabled"]), _createVNode(_component_el_button, {
                type: "primary",
                onClick: onSubmit
              }, {
                default: _withCtx(() => [_createTextVNode("确认")]),
                _: 1
              }), _createVNode(_component_el_button, {
                onClick: restSubmit
              }, {
                default: _withCtx(() => [_createTextVNode("重置")]),
                _: 1
              })]),
              _: 1
            })]),
            _: 1
          }, 8, ["model"])]),
          _: 1
        })]),
        _: 1
      }), _createElementVNode("div", _hoisted_1, [_createVNode(_component_el_row, {
        style: {
          "margin-top": "10px"
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_el_col, {
          span: 24
        }, {
          default: _withCtx(() => [_createVNode(_component_el_tooltip, {
            class: "box-item",
            effect: "dark",
            content: "设置",
            placement: "top"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_button, {
              icon: _unref(Setting),
              circle: ""
            }, null, 8, ["icon"])]),
            _: 1
          }), _createVNode(_component_el_tooltip, {
            class: "box-item",
            effect: "dark",
            content: "刷新",
            placement: "top"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_button, {
              icon: _unref(Refresh),
              circle: "",
              onClick: getTableData
            }, null, 8, ["icon"])]),
            _: 1
          }), _createVNode(_component_el_tooltip, {
            class: "box-item",
            effect: "dark",
            content: "入库",
            placement: "top"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_button, {
              icon: _unref(DocumentAdd),
              circle: "",
              onClick: _cache[3] || (_cache[3] = $event => openModalInventory('入库'))
            }, null, 8, ["icon"])]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      }), _createVNode(_component_el_divider), _createVNode(_component_el_row, {
        style: {
          "margin-bottom": "10px"
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_el_col, {
          span: 24
        }, {
          default: _withCtx(() => [_createVNode(_component_el_checkbox_group, {
            modelValue: selectedColumns.value,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => selectedColumns.value = $event)
          }, {
            default: _withCtx(() => [_createVNode(_component_el_checkbox, {
              label: "ID"
            }, {
              default: _withCtx(() => [_createTextVNode("ID")]),
              _: 1
            }), _createVNode(_component_el_checkbox, {
              label: "商品图"
            }, {
              default: _withCtx(() => [_createTextVNode("商品图")]),
              _: 1
            }), _createVNode(_component_el_checkbox, {
              label: "商品条码"
            }, {
              default: _withCtx(() => [_createTextVNode("商品条码")]),
              _: 1
            }), _createVNode(_component_el_checkbox, {
              label: "商品名称"
            }, {
              default: _withCtx(() => [_createTextVNode("商品名称")]),
              _: 1
            }), _createVNode(_component_el_checkbox, {
              label: "规格型号"
            }, {
              default: _withCtx(() => [_createTextVNode("规格型号")]),
              _: 1
            }), _createVNode(_component_el_checkbox, {
              label: "库存单位"
            }, {
              default: _withCtx(() => [_createTextVNode("库存单位")]),
              _: 1
            }), _createVNode(_component_el_checkbox, {
              label: "库存量"
            }, {
              default: _withCtx(() => [_createTextVNode("库存量")]),
              _: 1
            }), _createVNode(_component_el_checkbox, {
              label: "进货价"
            }, {
              default: _withCtx(() => [_createTextVNode("进货价")]),
              _: 1
            })]),
            _: 1
          }, 8, ["modelValue"])]),
          _: 1
        })]),
        _: 1
      }), _withDirectives((_openBlock(), _createBlock(_component_a_table, {
        data: tableData.value,
        style: {
          "width": "100%"
        },
        pagination: false,
        "empty-text": "暂无订单数据",
        fit: true
      }, {
        columns: _withCtx(() => [selectedColumns.value.includes('ID') ? (_openBlock(), _createBlock(_component_a_table_column, {
          key: 0,
          "data-index": "id",
          title: "ID",
          align: "center",
          fixed: "left"
        })) : _createCommentVNode("", true), selectedColumns.value.includes('商品图') ? (_openBlock(), _createBlock(_component_a_table_column, {
          key: 1,
          "data-index": "sortPhotoUrls",
          title: "商品图",
          align: "center"
        }, {
          cell: _withCtx(({
            record
          }) => [_createElementVNode("div", _hoisted_2, [!record.sortPhotoUrls ? (_openBlock(), _createElementBlock("span", _hoisted_3, "-")) : (_openBlock(), _createBlock(_component_el_image, {
            key: 1,
            style: {
              "width": "100px",
              "height": "100px"
            },
            src: record.sortPhotoUrls,
            "preview-src-list": [record.sortPhotoUrls],
            fit: "cover",
            "preview-teleported": true
          }, null, 8, ["src", "preview-src-list"]))])]),
          _: 1
        })) : _createCommentVNode("", true), selectedColumns.value.includes('商品条码') ? (_openBlock(), _createBlock(_component_a_table_column, {
          key: 2,
          "data-index": "barcode",
          fixed: "left",
          title: "商品条码"
        })) : _createCommentVNode("", true), selectedColumns.value.includes('商品名称') ? (_openBlock(), _createBlock(_component_a_table_column, {
          key: 3,
          "data-index": "subject",
          fixed: "left",
          title: "商品名称"
        })) : _createCommentVNode("", true), selectedColumns.value.includes('规格型号') ? (_openBlock(), _createBlock(_component_a_table_column, {
          key: 4,
          "data-index": "sortName",
          fixed: "left",
          title: "规格型号"
        })) : _createCommentVNode("", true), selectedColumns.value.includes('库存单位') ? (_openBlock(), _createBlock(_component_a_table_column, {
          key: 5,
          "data-index": "stock_unit",
          fixed: "left",
          title: "库存单位"
        })) : _createCommentVNode("", true), selectedColumns.value.includes('库存量') ? (_openBlock(), _createBlock(_component_a_table_column, {
          key: 6,
          "data-index": "stocks",
          fixed: "left",
          title: "库存量"
        })) : _createCommentVNode("", true), selectedColumns.value.includes('进货价') ? (_openBlock(), _createBlock(_component_a_table_column, {
          key: 7,
          "data-index": "purchase_price",
          fixed: "left",
          title: "进货价"
        })) : _createCommentVNode("", true), _createVNode(_component_a_table_column, {
          fixed: "left",
          title: "设置"
        }, {
          cell: _withCtx(({
            record
          }) => [_createElementVNode("div", null, [showcarButton.value ? (_openBlock(), _createBlock(_component_el_tooltip, {
            key: 0,
            class: "box-item",
            effect: "dark",
            content: "入库",
            placement: "top"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_button, {
              icon: _unref(DocumentAdd),
              circle: "",
              onClick: $event => openModalInventory('入库', record)
            }, null, 8, ["icon", "onClick"])]),
            _: 2
          }, 1024)) : _createCommentVNode("", true), record.stocks > 0 ? (_openBlock(), _createBlock(_component_el_tooltip, {
            key: 1,
            class: "box-item",
            effect: "dark",
            content: "出库",
            placement: "top"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_button, {
              icon: _unref(DocumentDelete),
              circle: "",
              onClick: $event => openModalInventory('出库', record)
            }, null, 8, ["icon", "onClick"])]),
            _: 2
          }, 1024)) : _createCommentVNode("", true)])]),
          _: 1
        })]),
        _: 1
      }, 8, ["data"])), [[_directive_loading, loading.value]])]), _createVNode(_component_el_row, {
        justify: "end",
        class: "mt-20"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_pagination, {
          onCurrentChange: handleCurrentChange,
          "page-size": pagination.page_size,
          modelValue: pagination.page_num,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => pagination.page_num = $event),
          background: "",
          layout: "total,prev, pager, next",
          total: pagination.total
        }, null, 8, ["page-size", "modelValue", "total"])]),
        _: 1
      })]), _createVNode(modalInventory, {
        ref_key: "modalInventoryRef",
        ref: modalInventoryRef,
        onRefresh: getTableData
      }, null, 512), _createVNode(modalCar, {
        ref_key: "modalCarRef",
        ref: modalCarRef,
        onRefresh: _cache[6] || (_cache[6] = $event => getRackData({
          storeId: formModel.value.storeId
        }))
      }, null, 512)], 64);
    };
  }

};